$platform-name: 'jupitermosmanhousingcompanyltd';

$brand-primary: #144188;
$brand-secondary: #7FD0EF;
$_brand-tertiary: #1c3254;

$grey-dark: #33363B;
$grey-light: #a0a0a0;
$grey-lightest: #f7f7f9;

$body-colour: $grey-dark;

$logo: 'logo.png';
$logo-width: 380px;
$logo-height: 92px;
$logo-mobile-width: $logo-width * .66;
$logo-mobile-height: $logo-height * .66;

$font-family-base: 'Roboto', sans-serif;
$font-weight-bold: 900;
$line-height-base: 1.75;

$container-width: 1230px;

$btn-padding-x: 1.25em;
$btn-padding-y: .625em;
$btn-font-weight: $font-weight-bold;
$btn-text-transform: uppercase;

$menu-admin-enabled: false;

$header-content-margin-top: 1.75em;
$header-content-margin-bottom: 1.75em;

$cta-btn-gap: .75em;

$header-search-margin-right: .75em;

$header-search-input-max-width: 180px;
$header-search-input-margin-right: 0;

// $nav-background-colour: transparent;
$nav-top-level-item-hover-colour: #fff;
$nav-top-level-item-hover-background-colour: $brand-primary;

$nav-submenu-width: 200px;
$nav-submenu-background-colour: darken($brand-primary, 7.5%);
$nav-submenu-item-colour: #fff;
$nav-submenu-item-font-size: .85rem;
$nav-submenu-item-hover-background-colour: rgba(#000,.2);
$nav-submenu-chevrons-colour: $brand-secondary;

$nav-normal-max-width: 100%;
$nav-normal-contents-max-width: $container-width;
$nav-normal-margin-bottom: $header-content-margin-bottom;

$burger-btn-bar-breakpoint: 490px;

$carousel-max-width: 1400px;
$carousel-details-background-colour: rgba(#000, .6);
$carousel-details-max-width: 600px;
$carousel-details-padding: 1.5rem;
$carousel-details-margin-y: 60px;
$carousel-details-margin-x: $carousel-details-margin-y;
$carousel-heading-colour: #fff;
$carousel-summary-font-size: 1.15em;
$carousel-read-more-font-size: .9rem;

$carousel-controls-margin-y: $carousel-details-margin-y;
$carousel-controls-margin-x: $carousel-details-margin-y;
$carousel-controls-button-gap: 5px;

$home-intro-background-image: 'bgrow.jpg';
$home-intro-content-max-width: 900px;
$home-intro-margin-top: 3.75rem;
$home-intro-padding-y: 3.75rem;
$home-intro-font-size: 1.65em;

$home-features-padding-top: 3.75rem;
$home-features-padding-bottom: 3.75rem;
$home-features-cards-entry-animation-prefab: 1;

$home-quick-giving-padding-y: 3.75rem;
$home-quick-giving-heading-font-size: 1.65em;
$home-quick-giving-donation-amount-background-colour: transparent;
$home-quick-giving-donation-amount-border-width: 4px;
$home-quick-giving-donation-amount-figure-colour: $brand-primary;
$home-quick-giving-donation-amount-selected-background-colour: $brand-secondary;
$home-quick-giving-donate-btn-border-colour: $brand-primary;

$quick-giving-donation-amount-background-colour: transparent;
$quick-giving-donation-amount-border-width: 4px;
$quick-giving-donation-amount-figure-colour: $brand-primary;
$quick-giving-donation-amount-selected-background-colour: $brand-secondary;
$quick-giving-donate-btn-border-colour: $brand-primary;

$impact-stats-padding-y: 3.75rem;
$impact-stats-heading-font-size: 1.65em;
$impact-stats-heading-margin-bottom: 1em;
$impact-stats-figure-colour: $brand-secondary;
$impact-stats-figure-font-size: 4em;

$home-feeds-cards-entry-animation-prefab: 1;

$feeds-title-font-size: 1.65em;

$footer-boxes-count: 3;
// $footer-background-colour: #737373;

$newsletter-background-colour: $_brand-tertiary;
$newsletter-heading-font-size: 1.65em;

$shop-enabled: false; // Client doesn't have a shop

$card-border: none;
$card-details-padding: 1.2rem 0;
$card-heading-font-size: 1.5em;

$card-hover-image-scale: 1.1;
$card-hover-heading-colour: $brand-secondary;

$post-content-max-width: 980px;

$sidebar-enabled: false;
$sidebar-layout: above;